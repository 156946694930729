/*
 * SonarQube
 * Copyright (C) 2009-2025 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

import * as React from 'react';

interface Props extends React.BaseHTMLAttributes<HTMLDivElement> {
  innerRef?: (instance: HTMLDivElement) => void;
  onFocusOut: () => void;
}

export class FocusOutHandler extends React.PureComponent<React.PropsWithChildren<Props>> {
  ref?: HTMLDivElement;

  componentDidMount() {
    setTimeout(() => {
      document.addEventListener('focusin', this.handleFocusOut);
    }, 0);
  }

  componentWillUnmount() {
    document.removeEventListener('focusin', this.handleFocusOut);
  }

  nodeRef = (node: HTMLDivElement) => {
    const { innerRef } = this.props;
    this.ref = node;
    innerRef?.(node);
  };

  handleFocusOut = () => {
    if (this.ref?.querySelector(':focus') === null) {
      this.props.onFocusOut();
    }
  };

  render() {
    const { onFocusOut, innerRef, children, ...props } = this.props;
    return (
      <div ref={this.nodeRef} {...props}>
        {children}
      </div>
    );
  }
}
